import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { refreshDataPlanning } from './services/driver/refreshData';
import BottomMenu from '@/components/driver/BottomMenu';
import { mixinUpdate } from '@/mixins/update';
export default defineComponent({
  name: 'App',
  components: {
    BottomMenu,
    IonApp,
    IonRouterOutlet
  },
  mixins: [mixinUpdate],

  setup() {
    refreshDataPlanning();
  },

  computed: {
    seeBottomMenu() {
      if (this.$route.name === 'Connexion') {
        return false;
      } else {
        return true;
      }
    }

  }
});