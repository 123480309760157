import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_bottom_menu = _resolveComponent("bottom-menu");

  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet");

  const _component_ion_app = _resolveComponent("ion-app");

  return _openBlock(), _createBlock(_component_ion_app, {
    "xml:lang": "fr"
  }, {
    default: _withCtx(() => [_ctx.seeBottomMenu ? (_openBlock(), _createBlock(_component_bottom_menu, {
      key: 0
    })) : (_openBlock(), _createBlock(_component_ion_router_outlet, {
      key: 1
    }))]),
    _: 1
  });
}